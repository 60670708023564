import React, { useRef } from "react";
import Lottie from "lottie-react";
import LottieSrc from "../Lotties/lf20_iq5qkber.json";
import Fliper from "../components/Fliper/Flip";
import { useSelector } from "react-redux";
import Won from "../components/Fliper/Won";
import Lost from "../components/Fliper/Lose";
import LoadingFlip from "../components/Fliper/Loading";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import NativeBalance from "../components/Fliper/getBalance";
import {
  Title,
  Container,
  SpanTextSec,
  LotWrap2,
  ImageWarp,
} from "../components/Fliper/styles";
import useSound from "use-sound";
import wonSfx from "../sounds/win.mp3";
import loseSfx from "../sounds/lose.mp3";
import FooterNav from "../components/Partials/FooterNav";
import SocialBar from "../components/Partials/Social";
import {SpanText} from './Home.Styles';
  
const SwitchCase = (props) => {
  const [play] = useSound(wonSfx);
  const [play2] = useSound(loseSfx);
  const lottieRef = useRef();
  switch (props.status) {
    case "won":
      !props.isMuted && play();
      return (
        <Won
          address={props.address}
          status={props.status}
          wonAmount={props.wonAmount}
          bet={props.bet}
          streak={props.streak}
        />
      );
    case "lose":
      !props.isMuted && play2();
      return <Lost count={props.count} />;
    case "loading":
      return <LoadingFlip bet={props.bet} count={props.count} />;
    case "normal":
      return (
        <Container>
          <div>
            <div>
              <NativeBalance />
              <Title>CHOOSE YOUR SIDE</Title>
            </div>
            <SpanTextSec>FLIP. WIN. REPEAT.</SpanTextSec>
            <LotWrap2>
              {props.count > 5 ? (
                <ImageWarp>
                  <img
                    alt="polyCoinFlip"
                    style={{ paddingBottom: "30px", position: "relative" }}
                    src={require(`../images/count/${props.count}.png`).default}
                  />
                </ImageWarp>
              ) : (
                <ImageWarp>
                  <Lottie lottieRef={lottieRef} animationData={LottieSrc} />
                </ImageWarp>
              )}
            </LotWrap2>

            <Fliper isMuted={props.isMuted} address={props.address} />
          </div>
          <FooterNav />
          <SocialBar />
          <SpanText style={{marginTop: '20px'}}>
            BY <u>MATICPLAY.IO</u>
          </SpanText>
        </Container>
      );
    default:
      return <h1>default</h1>;
  }
};
const FlipView = () => {
  const status = useSelector((state) => state.fliper.status);
  var count = useSelector((state) => state.fliper.value);
  var bet = useSelector((state) => state.fliper.bet);
  var address = useSelector((state) => state.fliper.address);
  var streak = useSelector((state) => state.fliper.streak);
  var wonAmount = useSelector((state) => state.fliper.claimAmount);
  var isMuted = useSelector((state) => state.fliper.isMuted);
  return (
    <SwitchCase
      isMuted={isMuted}
      status={status}
      wonAmount={wonAmount}
      bet={bet}
      address={address}
      streak={streak}
      count={count}
    />
  );
};

export default FlipView;
