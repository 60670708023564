import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import lostSrc from "../../images/loseTrophy.png";
import { setStatus } from "../../redux/FliperReducer";
import WonLottieSrc from "../../Lotties/74694-confetti.json";
import Lottie from "lottie-react";
import {
  SendBetButton,
  LottieBtnLoader,
  CongratsTitle,
  Container,
  HelperDiv,
  SpanTextSec,
  LostTitle,
  ImageAbs,
  LoseWrap,
} from "./styles";
import NativeBalance from "./getBalance";
import SocialBar from "../Partials/Social";

const Lost = ({ status, count }) => {
  const [btnload, setBtnLoad] = useState(false);
  const dispatch = useDispatch();
  const HandleSubmit = () => {
    setBtnLoad(true);
    setTimeout(function () {
      dispatch(setStatus("normal"));
    }, 200);
  };
  return (
    <Container style={{ minHeight: "60vh", paddingTop: "30px" }}>
      <NativeBalance />
      <CongratsTitle>SORRY</CongratsTitle>
      <SpanTextSec>
        YOU LOSE <br /> <LostTitle> {count} MATIC </LostTitle>
        <SpanTextSec>GOOD LUCK NEXT TIME</SpanTextSec>
      </SpanTextSec>

      <LoseWrap style={{ width: "350px" }}>
        <HelperDiv>
          <Lottie
            animationData={WonLottieSrc}
            autoPlay
            loop
            style={{height: "320px", position: 'relative', zIndex: '1'}}
          />
          <ImageAbs width="40%" src={lostSrc} />
        </HelperDiv>
        <SendBetButton onClick={HandleSubmit} style={{ marginTop: "25px" }}>
          DOUBLE OR NOTHING
          {btnload ? (
            <LottieBtnLoader>
              <TailSpin
                height="30"
                width="30"
                color="grey"
                ariaLabel="loading"
              />
            </LottieBtnLoader>
          ) : (
            ""
          )}
        </SendBetButton>
        <SocialBar />
      </LoseWrap>
    </Container>
  );
};

export default Lost;
