import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    transition: background 0.2s ease-in, color 0.2s ease-in;
  }
`;

export const Toggle = styled.div`
  cursor: pointer;
  position: fixed;
  margin-left: -10px;
  margin-top: 5px;
  z-index: 999;
  width:100%;
  display:flex;
  justify-content: flex-end;
`;


export const darkTheme = {
  FotNavText: "#ffffff",
  respTitle: "#ffffff",
  ModalBody: '#1b1b2f',
  colorTextOpacity: "#ffffff",
  colorText: "#ffffff",
  colorText2: "#000",
  body: "#12121bde",
  titleGrad: "#9C6CEA",
  statBox: "#6939B7",
  congratsTitle: "rgba(247, 191, 33, 1)",
  colors: {
    mainPurple: "#8347E5",
    white: "#FFFFFF",
  },
  border: "3px solid #6939B7",
  fontSizes: {
    small: "1em",
    medium: "2em",
    large: "3em",
  },
  shadow: {
    active: "1px 2px 7px #0000004f",
    normal: "none"},
  flex: "display:flex;justify-content:center;align-items:center;"
};
export const lightTheme = {
  FotNavText: "rgba(0, 0, 0, 0.8)",
  respTitle: "rgba(0, 0, 0, 0.6)",
  ModalBody: '#ffffff',
  body: "#ffffff",
  statBox: "#1b1b2f",
  colorTextOpacity: "rgba(0, 0, 0, 0.6)",
  colorText: "#000",
  colorText2: "#000",
  titleGrad: "linear-gradient(88.03deg, #8347E5 -11.81%, #7F3BED 16.05%, #9C6CEA 16.06%, #9C6CEA 23.64%, #8347E5 23.65%, #8347E5 55.79%, #9C6CEA 55.8%, #9C6CEA 66.48%, #8347E5 66.49%, #8347E5 102.06%);",
  congratsTitle: "rgba(247, 191, 33, 1)",
  colors: {
    mainPurple: "#8347E5",
    white: "#FFFFFF",
  },
  border: "3px solid #6939B7",
  fontSizes: {
    small: "1em",
    medium: "2em",
    large: "3em",
  },
  shadow: {
    active: "1px 2px 7px #0000004f",
    normal: "none"},
  flex: "display:flex;justify-content:center;align-items:center;"
};