import React from "react";
import ModalContent from "../Partials/Modal";
import SocialBar from "../Partials/Social";
import { ThanksButton } from "../../views/Home.Styles";
import {
  NavText,
  AboutStyledModal
} from './Modal.styles';

const AboutModal = ({ closeFn = () => null, open = false }) => {
  return (
    <AboutStyledModal onEscapeKeydown={closeFn} isOpen={open} contentLabel="About">
      <ModalContent />
      <ThanksButton onClick={closeFn} isActive={true}>
        THANKS
      </ThanksButton>
      <NavText>connect us using our social. website soon.</NavText>
      <SocialBar />
    </AboutStyledModal>
  );
};

export default AboutModal;
