import { useEffect } from "react";
import { Toggle } from "../../theme";
import { useSelector, useDispatch } from "react-redux";
import { setThemeRid } from "../../redux/FliperReducer";

import styled from "styled-components";

const CheckBoxWrapper = styled.div`
  position: relative;
`;
const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: -10px;
  width: 60px;
  height: 32px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: "🌞";
    display: flex;
    justify-content: center;
    align-items: end;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  &:checked + ${CheckBoxLabel} {
    background: #8347e5;
    &::after {
      content: "🌜";
      display: flex;
      justify-content: center;
      align-items: end;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      margin: 3px;
      background: #ffffff;
      box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
      transition: 0.2s;
      margin-left: 31px;
    }
  }
`;

const ToggleDark = () => {
  const theme = useSelector((state) => state.fliper.theme);
  const dispatch = useDispatch();

  const isDarkTheme = theme === "dark";

  const handleToggle = () => {
    const updatedTheme = isDarkTheme ? "light" : "dark";
    localStorage.setItem("theme", updatedTheme);
    dispatch(setThemeRid(updatedTheme));
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    const prefersDark =
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches;

    if (savedTheme && ["dark", "light"].includes(savedTheme)) {
      dispatch(setThemeRid(savedTheme));
    } else if (prefersDark) {
      dispatch(setThemeRid("dark"));
    }
  }, []);
  return (
    <Toggle>
      <CheckBoxWrapper onChange={handleToggle}>
        <CheckBox id="checkbox" defaultChecked={isDarkTheme} type="checkbox" />
        <CheckBoxLabel htmlFor="checkbox" />
      </CheckBoxWrapper>
    </Toggle>
  );
};

export default ToggleDark;
