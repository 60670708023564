import React, { useState, useEffect } from "react";
import Lottie from "lottie-react";
import firstLottie from "../Lotties/welcome.json";
import DarkfirstLottie from "../Lotties/lf20_ucn7axbq.json";

import { useNavigate } from "react-router-dom";
import { useMoralis } from "react-moralis";
import metaMaskLogo from "../images/metamask.svg";
import { useDispatch, useSelector } from "react-redux";
import { setAddress, setStatus, setClaimVal } from "../redux/FliperReducer";
import { didClaim } from "../utils/interact";
import toast, { Toaster } from "react-hot-toast";

import {
  AddMaticButton,
  HomeContainer,
  LogOutButton,
  Title,
  Container,
  LottieWrapper,
  SpanText,
  SpanTextSec,
} from "./Home.Styles";

import FooterNav from "../components/Partials/FooterNav";
import { useMixpanel } from "react-mixpanel-browser";

const HomeView = () => {
  const [isMatic, setIsMatic] = useState(false);
  const theme = useSelector((state) => state.fliper.theme);
  const userAddress = useSelector((state) => state.fliper.address);
  
  const mixpanel = useMixpanel();
  const dispatch = useDispatch();

  const { Moralis, isWeb3Enabled, authenticate, isAuthenticated, logout } =
    useMoralis();

  useEffect(() => {
    const unsub = Moralis.onWeb3Enabled(async (args) => {
      dispatch(setAddress(args.account));
    });
    return () => {
      unsub();
    };
  }, [Moralis]);

  const navigate = useNavigate();

  const handleClick = async () => {
    if (Moralis.getChainId() === process.env.REACT_APP_CHAINID) {
      mixpanel.track("User Authorized");
      dispatch(setStatus("normal"));
      navigate("/flip");
    } else {
      toast.error("Please change network to Polygon");
    }
  };

  const handleSuccess = async (arg) => {
    if (Moralis.getChainId() === process.env.REACT_APP_CHAINID) {
      let didUserClaimed = await didClaim(arg.attributes.accounts[0]);
      if (didUserClaimed.status) {
        dispatch(setClaimVal(didUserClaimed.amount / 1000000000000000000));
        dispatch(setStatus("won"));
        navigate("/flip");
        return;
      }
      dispatch(setStatus("normal"));
      setIsMatic(true);
      navigate("/flip");
    } else {
      toast.error("Please change network to Polygon");
    }
  };

  const addMatic = async () => {
    mixpanel.track("Click on add MATIC");
    await Moralis.authenticate({
      provider: "metamask",
      chainId: process.env.REACT_APP_CHAINID,
    });
    const chainIds = "0x89";
    const chainName = "Matic Mainnet";
    const currencyName = "MATIC";
    const currencySymbol = "MATIC";
    const rpcUrl = "https://polygon-rpc.com/";
    const blockExplorerUrl = "https://polygonscan.com/";

    await Moralis.addNetwork(
      chainIds,
      chainName,
      currencyName,
      currencySymbol,
      rpcUrl,
      blockExplorerUrl
    );
    setIsMatic(true);
  };
  const LogOut = () => {
    if (isAuthenticated || isWeb3Enabled) {
      return (
        <LogOutButton
          onClick={async () => {
            await logout();
            window.localStorage.removeItem("connectorId");
          }}
        >
          Logout {String(userAddress).substring(0, 4)} .. {String(userAddress).substring(38, 42)}
        </LogOutButton>
      );
    }
    return <div></div>;
  };

  function handleEthereum() {
    const { ethereum } = window;
    if (ethereum && ethereum.isMetaMask) {
      // console.log("Ethereum successfully detected!");
      // Access the decentralized web!
    } else {
      // alert("Play on Metamask browser!");
      window.location.href = "https://metamask.app.link/dapp/maticplay.io";
    }
  }

  return (
    <HomeContainer>
      <Toaster position="top-center" reverseOrder={false} />
      <SpanText>
        BY <u>MATICPLAY.IO</u>
      </SpanText>
      <Title>POLY COIN FLIP</Title>
      <SpanTextSec>FLIP. WIN. REPEAT.</SpanTextSec>
      <LogOut />
      <LottieWrapper>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleEthereum();
            !isAuthenticated
              ? authenticate({
                  provider: "metamask",
                  chainId: 137,
                  onSuccess: (arg) => {
                    handleSuccess(arg);
                  },
                })
              : handleClick();
          }}
        >
          <Lottie
            autoPlay={true}
            loop={true}
            animationData={theme === "light" ? firstLottie : DarkfirstLottie}
          />
        </div>

        {!isMatic && (
          <AddMaticButton onClick={addMatic}>
            Add Matic Network
            <img src={metaMaskLogo} alt="metamask" width="20px" />
          </AddMaticButton>
        )}
      </LottieWrapper>
      <FooterNav />
    </HomeContainer>
  );
};

export default HomeView;
