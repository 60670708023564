import react, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const useGaTracker = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!window.location.href.includes("localhost")) {
        ReactGA.initialize("G-9S1W9DGDVQ", {debug: false});
        }
        setInitialized(true);
    }, []);

    useEffect(() => {
        if (initialized) {
        ReactGA.pageview(location.pathname + location.search);
        if(location.pathname === "/flip"){
            console.log("here!!!!!!")
            ReactGA.event({
                category: 'User',
                action: 'Created an Account'
              });
        }
        }
    }, [initialized, location]);
};

export default useGaTracker;