import React from "react";
import { useNativeBalance } from "react-moralis";
import {BalanceText} from './styles';

const NativeBalance = () => {
  const {
    data: balance,
    isLoading
  } = useNativeBalance({ chainId: "0x89" });
  if(isLoading){
    return(
      <BalanceText>Loading...</BalanceText>
    )
  }
  else{
    return(
      <BalanceText>YOU HAVE {balance.formatted}</BalanceText>
    )
  }
  
};
export default NativeBalance;
