import React, { useState } from "react";
import styled from "styled-components";
import ModalManager from "../../components/Modals/ModalManager";

export const FooterNavWrap = styled.div`
  ${(props) => props.theme.flex};
  flex-wrap: wrap;
  padding-left: 10px;
  padding-right: 10px;
`;

export const NavText = styled.a`
  text-transform: uppercase;
  text-decoration: underline;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.04em;
  text-align: center;
  color: ${(props) => props.theme.FotNavText};
  cursor: pointer;
`;
export const Seperator = styled.div`
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.04em;
  text-align: center;
  margin-left: 6px;
  color: ${(props) => props.theme.FotNavText};
  margin-right: 6px;
`;

const FooterNav = () => {
  const [modalOpen, setModal] = useState(false);

  const openModal = (event) => {
    event.preventDefault();
    const {
      target: {
        dataset: { modal },
      },
    } = event;
    if (modal) setModal(modal);
  };

  const closeModal = () => {
    setModal("");
  };

  return (
    <div>
      <ModalManager closeFn={closeModal} modal={modalOpen} />

      <FooterNavWrap>
        <NavText data-modal="AboutModal" onClick={openModal}>
          ABOUT
        </NavText>
        <Seperator>|</Seperator>
        <NavText data-modal="FlipRespModal" onClick={openModal}>
          FLIP RESPONSIBLY
        </NavText>
        <Seperator>|</Seperator>
        <NavText data-modal="HowToModal" onClick={openModal}>
        HOW TO PLAY?
        </NavText>
        <Seperator>|</Seperator>
        <NavText data-modal="FAQModal" onClick={openModal}>
        FAQ
        </NavText>
      </FooterNavWrap>
    </div>
  );
};

export default FooterNav;
