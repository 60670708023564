import React from "react";
import styled from "styled-components";

const Title = styled.h1`
  background: ${(props) => props.theme.titleGrad};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  margin-block-end: 0.7em;
  font-size: 1.85em;
  text-align: center;
`;

const Paragraph = styled.div`
  text-transform: uppercase;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.04em;
  text-align: left;
  color: ${(props) => props.theme.colorText};
  padding-left: 10px;
  padding-right: 10px;
`;

export const SpanText2 = styled.div`
text-transform: uppercase;
width:100%;
font-family: Rubik;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 26px;
letter-spacing: 0.04em;
text-align: center;
`;
const ModalContent = () => {
  return (
    <div>
      <Title>ABOUT</Title>
      <Paragraph>
        from <u>maticplay.io</u> creators poly coin flip is A play to earn game based
        on polygon & matic ecosystem where the PCF smart contract provides 50:50 ODDs
        of doubling or losing your money.
        every transaction is on-chain. We hope that you will enjoy and earn.
        <br />
        <br />
        <b>
          although this is a game, it is includ gambling and as you know it can be very
          addictive. PLEASE flip responsiblly
        </b>
      </Paragraph>
    </div>
  );
};

export default ModalContent;
