import React from "react";
import {
  FAQStyledModal,
  Title,
  Paragraph,
  ConfirmButton,
  FaqTitle,
  UsflLink
} from "./Modal.styles";

import SocialBar from "../Partials/Social";

const FAQModal = ({ closeFn = () => null, open = false }) => {
  return (
    <FAQStyledModal
      onEscapeKeydown={closeFn}
      isOpen={open}
      contentLabel="About"
    >
      <Title>Frequently Asked Questions</Title>
      <FaqTitle>What is Poly Coin Flip (PCF)?</FaqTitle>
      <hr />
      <Paragraph>
        Poly Coin Flip is a smart contract that allows users to flip a coin (Double or
        Nothing) with their <b>$MATIC</b> tokens. Odds are 50/50 with a 3.5% fee that
        goes to PCF NFT holders.
        <br />
      </Paragraph>

      <FaqTitle>How do you ensure the randomness of the results?</FaqTitle>
      <hr />
      <Paragraph>
        We share 100% of our data using MixPanel public dashboard - 
        You can easily see the distributions of the "Flip" event and see that the odds strives to 50%.

      </Paragraph>

      <FaqTitle>Where can I track transactions?</FaqTitle>
      <hr />
      <Paragraph>
        Smart Contract:
        <UsflLink href="https://polygonscan.com/address/0x9c0f5fc30b85dc32df436317d29c6dee0fd2d3ba" aria-label="feewalet">View</UsflLink>
        <br/>
        
        Fee Wallet:
        <UsflLink href="https://polygonscan.com/address/0x5d2E76523a96888f228204De20b5Df1d541E2FB6" aria-label="feewalet">View</UsflLink>
        <br/>
      </Paragraph>
      <FaqTitle>Where can I learn more?</FaqTitle>
      <hr />
      <Paragraph>
        Join us on discord, there will always be someone to help you out.
        <UsflLink href="https://discord.io/maticplay">JOIN DISCORD</UsflLink>
      </Paragraph>
      <SocialBar />
      <ConfirmButton style={{marginTop: '20px'}}  onClick={closeFn}>GOT IT</ConfirmButton>
    </FAQStyledModal>
  );
};

export default FAQModal;
