import styled from "styled-components";
import Modal from "styled-react-modal/build/umd";

export const UsflLink = styled.a`
  color: ${(props) => props.theme.FotNavText};
`;
export const ModalParagraph = styled.p`
  color: ${({ theme }) => theme.colorText};
  font-size: 0.8em;
  margin-block-end: 1em;
  font-weight: 600;
  letter-spacing: 0.05em;
  line-height: 26px;
  text-transform: uppercase;
`;
export const StuckStyledModal = Modal.styled` 
  width: 300px;
  border: 1px solid #ccc;
  overflow: auto;
  WebkitOverflowScrolling: touch;
  borderRadius: 20px;
  outline: none;
  padding: 20px 20px;
  border: 6px solid rgb(236 160 49 / 68%);
  background-color: ${(props) => props.theme.ModalBody};
`;
export const FAQStyledModal = Modal.styled` 
@media screen and (max-width: 479px) {
  max-width: 82%;
}
@media screen and (max-width: 379px) {
  max-width: 82%;
  top: 0;
  bottom: 0;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
}
  max-width: 620px;
  border: 1px solid #ccc;
  overflow: auto;
  WebkitOverflowScrolling: touch;
  borderRadius: 20px;
  outline: none;
  padding: 20px 20px;
  border: 6px solid rgb(236 160 49 / 68%);
  background-color: ${(props) => props.theme.ModalBody};
  color: white;
`;
export const HowToStyledModal = Modal.styled` 
@media screen and (max-width: 479px) {
  max-width: 82%;
}
@media screen and (max-width: 379px) {
    max-width: 82%;
    top: 0;
    bottom: 0;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  max-width: 620px;
  border: 1px solid #ccc;
  overflow: auto;
  WebkitOverflowScrolling: touch;
  borderRadius: 20px;
  outline: none;
  padding: 20px 20px;
  border: 6px solid rgb(236 160 49 / 68%);
  background-color: ${(props) => props.theme.ModalBody};
  color: white;
`;
export const AboutStyledModal = Modal.styled` 
@media screen and (max-width: 479px) {
  max-width: 82%;
}
@media screen and (max-width: 379px) {
  max-width: 82%;
  top: 0;
  bottom: 0;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
}
  max-width: 620px;
  border: 1px solid #ccc;
  overflow: auto;
  WebkitOverflowScrolling: touch;
  borderRadius: 20px;
  outline: none;
  padding: 20px 20px;
  border: 6px solid rgb(236 160 49 / 68%);
  background-color: ${(props) => props.theme.ModalBody};
  color: white;
`;

export const FlipRespStyledModal = Modal.styled` 
@media screen and (max-width: 479px) {
  max-width: 82%;
}
@media screen and (max-width: 379px) {
  max-width: 82%;
  top: 0;
  bottom: 0;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
}
  max-width: 620px;
  border: 1px solid #ccc;
  overflow: auto;
  WebkitOverflowScrolling: touch;
  borderRadius: 20px;
  outline: none;
  padding: 20px 20px;
  border: 6px solid rgb(236 160 49 / 68%);
  background-color: ${(props) => props.theme.ModalBody};
  color: white;
`;

export const Title = styled.h1`
  background: ${(props) => props.theme.respTitle};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  margin-block-end: 0.7em;
  font-size: 1.25em;
  text-align: left;
`;
export const FaqTitle = styled.h1`
  background: ${(props) => props.theme.respTitle};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  margin-block-end: 0.7em;
  font-size: 1.05em;
  text-align: left;
`;
export const Paragraph = styled.div`
  text-transform: uppercase;
  font-family: Rubik;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.04em;
  text-align: left;
  color: ${(props) => props.theme.colorText};
`;
export const ConfirmButton = styled.div`
  cursor: pointer;
  ${(props) => props.theme.flex};
  width: 255px;
  height: 40px;
  font-weight: 800;
  font-size: 20px;
  letter-spacing: 0.04em;
  margin: 0 auto;
  background: rgb(236 160 49 / 100%);
  color: #ffffff;
  box-shadow: ${({ isActive, theme: { shadow } }) =>
    isActive ? shadow.active : shadow.normal};
`;
export const NavText = styled.a`
  text-transform: uppercase;
  text-decoration: underline;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.04em;
  text-align: center;
`;
export const Seperator = styled.div`
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.04em;
  text-align: center;
  margin-left: 6px;
  margin-right: 6px;
`;
