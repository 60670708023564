import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setMute } from "../../redux/FliperReducer";
import {
  NavButton,
  NavWrapper,
  SpanTextSec,
  Title,
  Container,
  LotWrap,
} from "./styles";
import LottieFlipSrc from "../../Lotties/flip.json";
import Lottie from "lottie-react";
import NativeBalance from "./getBalance";
import { FaVolumeUp, FaVolumeMute } from "react-icons/fa";
import SocialBar from "../Partials/Social";
import ModalManager from "../../components/Modals/ModalManager";

const LoadingFlip = ({ bet, count }) => {
  const isMuted = useSelector((state) => state.fliper.isMuted);
  const dispatch = useDispatch();
  const [modalOpen, setModal] = useState(false);

  const handleMute = () => {
    if (isMuted) {
      dispatch(setMute(false));
    } else {
      dispatch(setMute(true));
    }
  };

  const openModal = (event) => {
    event.preventDefault();
    const {
      target: {
        dataset: { modal },
      },
    } = event;
    if (modal) setModal(modal);
  };

  const closeModal = () => {
    setModal("");
  };

  return (
    <div>
      <ModalManager closeFn={closeModal} modal={modalOpen} />
      <Container>
        <NativeBalance style={{ marginBottom: "45px" }} />
        <LotWrap>
          <Lottie loop={true} animationData={LottieFlipSrc} />
        </LotWrap>
        <Title style={{ marginTop: "50px" }}>FLIPPING</Title>
        <SpanTextSec style={{ lineHeight: "28px" }}>
          {bet} FOR {count} MATIC
        </SpanTextSec>
        <SpanTextSec
          style={{
            lineHeight: "28px",
            fontSize: "14px",
            letterSpacing: "0.05em",
            color: "#7f3bed",
          }}
        >
          USUALLY A FLIP TAKES <br /> UP TO 40 SECONDS
        </SpanTextSec>
        <SpanTextSec
          style={{
            marginTop: "20px",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          data-modal="StuckModal"
          onClick={openModal}
        >
          STUCK?
        </SpanTextSec>

        <SocialBar />

        <div>
          <NavWrapper>
            <NavButton data-modal="AboutModal" onClick={openModal}>
              ABOUT
            </NavButton>
            <NavButton onClick={handleMute}>
              {!isMuted ? (
                <FaVolumeUp size="23px" />
              ) : (
                <FaVolumeMute size="23px" />
              )}
            </NavButton>
          </NavWrapper>
        </div>
      </Container>
    </div>
  );
};

export default LoadingFlip;
