import React, { useState, useEffect } from "react";
import { useMoralis } from "react-moralis";
import { getStatistics } from "../utils/interact";
import {
  StatText,
  StatWrap,
  StatContainer,
  SpanText,
  SpanTextSec,
} from "./Home.Styles";
import { UsflLink } from "../components/Modals/Modal.styles";
const StatView = () => {
  const [data, setData] = useState({
    totalNumberOfFlips: "",
    totalMoneyFliped: "",
    headFlipped: "",
    totalHeadMoney: "",
    numberOfHeadWins: "",
    tailFlipped: "",
    totalTailMoney: "",
    numberOfTailWins: "",
  });
  console.log(data);
  useEffect(() => {
    async function fetchData() {
      const response = await getStatistics();
      console.log(data);
      setData(response);
      // ...
    }
    fetchData();
  }, []);

  return (
    <StatContainer>
      <StatWrap>
        <StatText>Total # of Flips:</StatText>
        <StatText>{data.totalNumberOfFlips}</StatText>
      </StatWrap>
      <StatWrap>
        <StatText>Total Flipped:</StatText>
        <StatText>
          {data.totalMoneyFliped} <b>$MATIC</b>{" "}
        </StatText>
      </StatWrap>
      <StatWrap>
        <StatText>Choosed Heads:</StatText>
        <StatText>
          {(data.headFlipped / data.totalNumberOfFlips).toFixed(2) * 100}%
        </StatText>
      </StatWrap>
      <StatWrap>
        <StatText>Choosed Tails:</StatText>
        <StatText>
          {(data.tailFlipped / data.totalNumberOfFlips).toFixed(2) * 100}%
        </StatText>
      </StatWrap>
      <StatWrap>
        <StatText>Random Heads:</StatText>
        <StatText> {(data.numberOfHeadWins / data.totalNumberOfFlips).toFixed(2) * 100}%</StatText>
      </StatWrap>
      <StatWrap>
        <StatText>Random Tails:</StatText>
        <StatText> {(data.numberOfTailWins / data.totalNumberOfFlips).toFixed(2) * 100}%</StatText>
      </StatWrap>
      
      
    </StatContainer>
  );
};

export default StatView;
