import React from "react";
import {
  HowToStyledModal,
  Title,
  Paragraph,
  ConfirmButton,
  UsflLink
} from "./Modal.styles";

const HowToPlayModal = ({ closeFn = () => null, open = false }) => {
  return (
    <HowToStyledModal
      onEscapeKeydown={closeFn}
      isOpen={open}
      contentLabel="About"
    >
      <Title>How To Play?</Title>
      <Paragraph>
        1. Connect your MetaMask Wallet.{" "}
        <UsflLink href="https://metamask.io/download/">Click here to download</UsflLink> <br />
        2. Click Add matic network at the bottom of our home page <br />
        3. Pick either heads or tails. <br />
        4. Select your desired flip amount. <br />
        5. Click “Double or Nothing”. <br />
        6. Click approve and wait for coin to spin <br />
        7. Congrats. <br />
      </Paragraph>

      <Title>What is a MetaMask Wallet?</Title>
      <Paragraph>
        MetaMask wallet is a browser extension to manage your digital assets on
        the Ethereum blockchain network. Visit metamask.io/download, add the
        wallet to chrome, and follow the instructions to create a wallet.
        MetaMask is a global community of developers and designers dedicated to
        making the world a better place with blockchain technology. <br />
      </Paragraph>

      <Title>How Do I fund my MetaMask Wallet?</Title>
      <Paragraph  style={{ marginBottom: "20px" }}>
        Use a central exchange such as Coinbase, Binance, or FTX to fund your
        wallet. Purchase $MATIC using fiat currency. Then withdraw $MATIC to
        your MetaMask wallet.
      </Paragraph>

      <ConfirmButton onClick={closeFn}>UNDERSTOOD</ConfirmButton>
    </HowToStyledModal>
  );
};

export default HowToPlayModal;
