import React from "react";
import { Bars } from "../../views/Home.Styles";
import { FaDiscord, FaTwitter, FaTiktok } from "react-icons/fa";
import { useSelector } from "react-redux";

const SocialBar = () => {
  const theme = useSelector((state) => state.fliper.theme);
  return (
    <Bars>
      <a
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "black" }}
        href="https://discord.io/maticplay"
      >
        <FaDiscord size="30px" color="#5865f2" />
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "black" }}
        href="https://twitter.com/polycoinflip?s=11"
      >
        <FaTwitter size="30px" color="#1da1f2" />
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "black" }}
        href="https://vm.tiktok.com/ZSewWdJLc/"
      >
        <FaTiktok
          size="27px"
          color={theme === "light" ? "rgb(0 0 0 / 75%)" : "#ffffff"}
        />
      </a>
    </Bars>
  );
};

export default SocialBar;