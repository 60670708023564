import React from "react";
import Modal from "styled-react-modal/build/umd";

import { ConfirmButton,Title, ModalParagraph, StuckStyledModal } from "./Modal.styles";


const StuckModal = ({ closeFn = () => null, open = false }) => {
  return (
    <StuckStyledModal
      onEscapeKeydown={closeFn}
      isOpen={open}
      contentLabel="Stuck"
    >
      <Title>ARE YOU STUCK?</Title>
      <ModalParagraph>
        Polygon may still be processing your previous flip. This may result in a
        UI glitch where you see your previous coin flip's result. <br /> <br />
        We will not double transact you without completing a flip. Pause for a
        bit, re-evaluate your strategy, and then try again once Polygon syncs
        up.
        <br />
        <br />
        Join our <a href="https://discord.io/maticplay">discord</a> for more
        support.
      </ModalParagraph>
      <br />
      <ConfirmButton onClick={closeFn}>CONFIRM</ConfirmButton>
    </StuckStyledModal>
  );
};

export default StuckModal;
