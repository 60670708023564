import { useEffect } from "react";

import { ThemeProvider } from "styled-components";
import { darkTheme, lightTheme, GlobalStyles } from "./theme";
import { ModalProvider } from "styled-react-modal/build/umd";
import { Routes, Route } from "react-router-dom";

import { useMoralis } from "react-moralis";
import { useSelector, useDispatch } from "react-redux";
import { setAddress, setThemeRid } from "./redux/FliperReducer";
import { MixpanelProvider } from "react-mixpanel-browser";
import useGaTracker from "./utils/GATracker";

import ToggleDark from "./components/Toggle/ToggleDark";
import FlipView from "./views/FlipView";
import HomeView from "./views/Home";
import "./App.css";
import StatView from "./views/Stat";

function App() {
  const {
    Moralis,
    isWeb3Enabled,
    enableWeb3,
    isAuthenticated,
    isWeb3EnableLoading,
    chainId,
  } = useMoralis();
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.fliper.theme);
  const isDarkTheme = theme === "dark";

  useEffect(() => {
    try {
      Moralis.onAccountChanged(async (args) => {
        dispatch(setAddress(args));
      });
    } catch (error) {
      console.log(error);
    }

    const connectorId = window.localStorage.getItem("connectorId");
    if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading)
      enableWeb3({ provider: connectorId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isWeb3Enabled]);
  var correctChain;
  if (chainId === process.env.REACT_APP_CHAINID) {
    correctChain = true;
  } else {
    correctChain = false;
  }
  useGaTracker();
  return (
    <MixpanelProvider>
      <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
        <ToggleDark />
        <GlobalStyles />
        <ModalProvider>
          <Routes>
            <Route path="/" element={<HomeView />} />
            {isAuthenticated && correctChain ? (
              <Route>
                <Route path="/flip" element={<FlipView />} />
                <Route path="/stats" element={<StatView />} />
              </Route>
            ) : (
              <Route path="/flip" element={<HomeView />} />
            )}
          </Routes>
        </ModalProvider>
      </ThemeProvider>
    </MixpanelProvider>
  );
}

export default App;
