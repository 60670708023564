import { createSlice } from "@reduxjs/toolkit";

export const filperSlice = createSlice({
  name: "fliper",
  initialState: {
    bet: "Heads",
    value: 5,
    streak: 0,
    status: "normal",
    hasNotClaimd: false,
    claimAmount: 0,
    lastBetRes: null,
    address: null,
    userBalance: null,
    isMuted: false,
    theme: "light",
  },
  reducers: {
    incrementByAmount: (state, action) => {
      state.value = action.payload;
    },
    chooseBet: (state, action) => {
      state.bet = action.payload;
    },
    setStreak: (state, action) => {
      state.streak = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    sendBetRes: (state, action) => {
      state.lastBetRes = action.payload;
    },
    setAddress: (state, action) => {
      state.address = action.payload;
    },
    setClaimVal: (state, action) => {
      state.claimAmount = action.payload;
    },
    setBalance: (state, action) => {
      state.userBalance = action.payload;
    },
    setMute: (state, action) => {
      state.isMuted = action.payload;
    },
    setThemeRid: (state, action) => {
      state.theme = action.payload;
    },
    resetState: (state, action) => {
      state = {
        bet: "Head",
        value: 5,
        streak: 0,
        status: "normal",
        hasNotClaimd: false,
        claimAmount: 0,
        lastBetRes: null,
        address: null,
        userBalance: null,
        isMuted: false,
        theme: "light"
      };
    },
  },
});

export const {
  setThemeRid,
  resetState,
  setMute,
  setClaimVal,
  setAddress,
  incrementByAmount,
  chooseBet,
  setStreak,
  setStatus,
  sendBetRes,
  claimAmount,
} = filperSlice.actions;

export default filperSlice.reducer;

