const Moralis = require("moralis");
const ethers = Moralis.web3Library;

const PRICE = 5.16666666668;
const serverUrl = "https://wabhlzhvwn13.usemoralis.com:2053/server";
const appId = "9aOeJ6pIUR29bGlE5PS4VUrakpMrUXdOUq4Rub6a";
const contractAddress = "0x9c0f5fc30B85dC32Df436317D29C6DEE0FD2d3Ba";
const contractAddress2 = "0xdad9535DE00C233f402FD48D1C784E2c96c93071";
Moralis.start({ serverUrl: serverUrl, appId: appId });

const contractABI = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "newFACTOR",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "newPrice",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "newminFill",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "ownerAddress",
        type: "address",
      },
    ],
    name: "ValueChanged",
    type: "event",
  },
  {
    inputs: [],
    name: "adminDraw",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "fillMoney",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bool",
        name: "_guess",
        type: "bool",
      },
      {
        internalType: "uint256",
        name: "multi",
        type: "uint256",
      },
    ],
    name: "flip",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "headFlipped",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "headMoney",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "headWon",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "house",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "minFill",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "ownerAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "price",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "newFACTOR",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "newPrice",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "newminFill",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "newOwnerAddress",
        type: "address",
      },
    ],
    name: "store",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "sumFlipped",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "sumMoneyPlayers",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "tailFlipped",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "tailMoney",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "tailWon",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "winStreakOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "withdraw",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
];

const contractABI2 = [
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "newFACTOR",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "newPrice",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "newminFill",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "address",
        "name": "ownerAddress",
        "type": "address"
      }
    ],
    "name": "ValueChanged",
    "type": "event"
  },
  {
    "inputs": [],
    "name": "adminDraw",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_Amount",
        "type": "uint256"
      }
    ],
    "name": "adminDrawPart",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "name": "balanceOf",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "fillMoney",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bool",
        "name": "_guess",
        "type": "bool"
      },
      {
        "internalType": "uint256",
        "name": "multi",
        "type": "uint256"
      }
    ],
    "name": "flip",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "headFlipped",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "headMoney",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "headWon",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "house",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "minFill",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "ownerAddress",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_Amount",
        "type": "uint256"
      }
    ],
    "name": "pay",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "price",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_bankAddress",
        "type": "address"
      }
    ],
    "name": "setbankAddress",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "newFACTOR",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "newPrice",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "newminFill",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "newOwnerAddress",
        "type": "address"
      }
    ],
    "name": "store",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "sumFlipped",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "sumMoneyPlayers",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "tailFlipped",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "tailMoney",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "tailWon",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "name": "winStreakOf",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "withdraw",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  }
];

export const didClaim = async (address) => {  
  try {
    var falied = true;
    let res1;
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    const PCFContractRead = new ethers.Contract(
      contractAddress2,
      contractABI2,
      provider
    );
    while(falied) {
      try{
        res1 = await PCFContractRead.balanceOf(address)
        falied = false
      }catch(err){
        console.log(err)
      }
      
    }
    
    console.log(`flipping res1: ${res1}`);
    const options = {
      contractAddress: contractAddress2,
      functionName: "balanceOf",
      abi: contractABI2,
      params: {
        note: "Did user claim",
        "": address,
      },
      msgValue: Moralis.Units.ETH(0),
    };

    //let res1 = await Moralis.executeFunction(options);
    if (parseInt(Number(res1)) > 0) {
      return { status: true, amount: parseInt(Number(res1)) };
    }
    return { status: false, amount: 0 };
  } catch (err) {
    console.log("error");
    return err;
  }
};

//* @params guess is Boolean while true is head. multiply is the bet number and address is the caller address.
//* for example multiply 3 is betting on 0.15 and 4 is 0.2 and so on commission is already included in the contract

export const newFlip = async (guess, multi, address) => {
  try {
    var falied1 = true;
    var falied2 = true;
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    const signer = provider.getSigner();
    const PCFContract = new ethers.Contract(
      contractAddress2,
      contractABI2,
      signer
    );
    const PCFContractRead = new ethers.Contract(
      contractAddress2,
      contractABI2,
      provider
    );
    const tx = {
      from: address,
      value: ethers.utils.parseEther((PRICE * multi).toString()),
      nonce: await provider.getTransactionCount(address, "latest"),
      gasLimit: ethers.utils.hexlify(250000),
      gasPrice: ethers.utils.hexlify(
        parseInt((await provider.getGasPrice()) * 2)
      ),
    };
    var res;
    while(falied1) {
      try{
        res = await PCFContract.flip(guess, multi, tx);
        falied1 = false
      }catch(err){
        if(err.code === 4001){
          falied1 = false
          return -1;
        }
        

      }
    }
 
    await res.wait(1);
    let res1;
    while(falied2) {
      try{
        res1 = await PCFContractRead.balanceOf(address)
        falied2 = false
      }catch(err){console.log(err)}
    }
    if (parseInt(Number(res1)) === 0) {
      return 0;
    }
    return parseInt(Number(res1));
  } catch (e) {
    console.log(e);
    return -1;
  }
};

// owner can take all the money the contract contains
export const withdrawAdmin = async () => {
  try {
    let options = {
      contractAddress: contractAddress2,
      functionName: "adminDraw",
      abi: contractABI2,
      params: {
        note: "Congrats",
      },
      msgValue: Moralis.Units.ETH(0),
    };
    const response = await Moralis.executeFunction(options);
    await response.wait();
    return true;
  } catch (err) {
    console.log("Error", err);
    return false;
  }
};

// withdraw the user reward money from the contract
export const clientWithDraw = async (address) => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    const signer = provider.getSigner();
    const PCFContract = new ethers.Contract(
      contractAddress2,
      contractABI2,
      signer
    );

    const tx = {
      from: address,
      value: ethers.utils.parseEther("0"),
      nonce: await provider.getTransactionCount(address, "latest"),
      gasLimit: ethers.utils.hexlify(200000),
      gasPrice: ethers.utils.hexlify(
        parseInt((await provider.getGasPrice()) * 2)
      ),
    };

    const res = await PCFContract.withdraw(tx);
    await res.wait(1)
    return true;

  } catch (e) {
    console.log(e);
    return false;
  }
};

// min amount you can fill to the contract with is 0.1 matic(money parameter)
// use it for the first fill of the contract
export const fillMoney = async (money = 2000) => {
  try {
    let options = {
      contractAddress: contractAddress2,
      functionName: "fillMoney",
      abi: contractABI2,
      params: {
        note: "Congrats",
      },
      msgValue: Moralis.Units.ETH(money),
    };
    await Moralis.executeFunction(options);
    return true;
  } catch (err) {
    console.log("Error", err);
    return false;
  }
};

//inpurt the address and get the winstreak he is on
export const getWinStreak = async (address) => {
  try {
    const options = {
      contractAddress: contractAddress2,
      functionName: "winStreakOf",
      abi: contractABI2,
      params: {
        note: "Congrats",
        "": address,
      },
      msgValue: Moralis.Units.ETH(0),
    };
    let res1 = await Moralis.executeFunction(options);
    console.log(parseInt(Number(res1)));
    return parseInt(Number(res1));
  } catch (err) {
    console.log(err);
    return;
  }
};

// Returns object of all the statistics and if failed returns null
export const getStatistics = async () => {
  try {
    let options = {
      contractAddress: contractAddress2,
      functionName: "sumFlipped",
      abi: contractABI2,
    };
    const result = await Moralis.executeFunction(options);
    const {
      0: variable_0,
      1: variable_1,
      2: variable_2,
      3: variable_3,
      4: variable_4,
      5: variable_5,
      6: variable_6,
      7: variable_7,
      8: variable_8,
    } = result;
    const data = {
      totalNumberOfFlips: parseInt(Number(variable_0)),
      totalMoneyFliped: parseInt(Number(variable_1)),
      headFlipped: parseInt(Number(variable_2)),
      totalHeadWinMoney: parseInt(Number(variable_3)),
      numberOfHeadWins: parseInt(Number(variable_4)),
      tailFlipped: parseInt(Number(variable_5)),
      totalTailWinMoney: parseInt(Number(variable_6)),
      numberOfTailWins: parseInt(Number(variable_7)),
    };
    return data;
  } catch (err) {
    console.log("Error", err);
    return null;
  }
};
