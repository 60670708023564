import styled from "styled-components";

export const LoseWrap = styled.div`
  @media screen and (max-width: 479px) {
    height: 350px;
  }
  height: 365px;
`;
export const ConfirmButton = styled.div`
  cursor: pointer;
  ${(props) => props.theme.flex};
  width: 155px;
  height: 40px;
  font-weight: 800;
  font-size: 20px;
  letter-spacing: 0.04em;
  margin: 0 auto;
  background: rgb(236 160 49 / 100%);
  color: #ffffff;
  box-shadow: ${({ isActive, theme: { shadow } }) =>
    isActive ? shadow.active : shadow.normal};
`;
export const Paragraph = styled.p`
  color: ${({ theme }) => theme.colorText};
  font-size: 1em;
  margin-block-end: 1em;
  font-weight: 600;
  letter-spacing: 0.18em;
  line-height: 26px;
  text-transform: uppercase;
`;
export const ModalParagraph = styled.p`
  color: ${({ theme }) => theme.colorText};
  font-size: 0.8em;
  margin-block-end: 1em;
  font-weight: 600;
  letter-spacing: 0.05em;
  line-height: 26px;
  text-transform: uppercase;
`;
export const BetButton = styled.div`
  cursor: pointer;
  ${(props) => props.theme.flex};
  width: 155px;
  height: 40px;
  font-weight: 800;
  font-size: 20px;
  letter-spacing: 0.04em;
  border: ${(props) => props.theme.border};
  background: ${({ isActive, theme: { colors } }) =>
    isActive ? colors.mainPurple : colors.white};
  color: ${({ isActive, theme: { colors } }) =>
    isActive ? colors.white : colors.mainPurple};
  box-shadow: ${({ isActive, theme: { shadow } }) =>
    isActive ? shadow.active : shadow.normal};
`;
export const AmountButton = styled.div`
  cursor: pointer;
  ${(props) => props.theme.flex};
  width: 95px;
  height: 40px;
  font-weight: 800;
  font-size: 13.57px;
  border: ${(props) => props.theme.border};
  background: ${({ isActive, theme: { colors } }) =>
    isActive ? colors.mainPurple : colors.white};
  color: ${({ isActive, theme: { colors } }) =>
    isActive ? colors.white : colors.mainPurple};
  box-shadow: ${({ isActive, theme: { shadow } }) =>
    isActive ? shadow.active : shadow.normal};
`;
export const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin: 0 auto;
  margin-top: 25px;
  margin-bottom: 25px;
  max-width: 390px;
`;
export const NavWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin: 0 auto;
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 0px 2px;
`;
export const NavButton = styled.div`
  cursor: pointer;
  ${(props) => props.theme.flex};
  width: 105px;
  height: 30px;
  font-weight: 800;
  font-size: 13.57px;
  border: ${(props) => props.theme.border};
  background: ${({ isActive, theme: { colors } }) =>
    isActive ? colors.mainPurple : colors.white};
  color: ${({ isActive, theme: { colors } }) =>
    isActive ? colors.white : colors.mainPurple};
`;
export const BetButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
`;
export const ImageWarp = styled.div`
  height: 175.28px;
  @media screen and (min-width: 479px) {
    height: 214.81px;
    align-items: end !important;
  }
  ${(props) => props.theme.flex};
  align-items: flex-end !important;
`;
export const LottieBtnLoader = styled.div`
  position: absolute;
  background: rgba(13, 13, 13, 0.59);
  width: 100%;
  height: 100%;
  ${(props) => props.theme.flex};
`;
export const SendBetButton = styled.button`
  position: relative;
  z-index: 3;
  font-family: "Rubik";
  cursor: pointer;
  ${(props) => props.theme.flex};
  border: ${(props) => props.theme.border};
  background: ${({ isActive, theme: { colors } }) =>
    isActive ? colors.mainPurple : colors.white};
  color: ${({ isActive, theme: { colors } }) =>
    isActive ? colors.white : colors.mainPurple};
  font-weight: 800;
  letter-spacing: 0.05em;
  margin-top: 20px;
  height: 50px;
  font-size: 20px;
  width: 345px;
  margin: 0 auto;
  margin-top: 40px!important;

  @media screen and (max-width: 479px) {
    margin-top: 10px!important;
  }
`;
export const LostTitle = styled.h1`
  color: #f95252;
  font-weight: 700;
  margin-block-end: 0.37em;
  font-size: 2.25em;
`;
export const CongratsTitle = styled.h1`
  color: ${(props) => props.theme.congratsTitle};
  font-weight: 700;
  margin-block-end: 0.37em;
  font-size: 2.25em;
  margin-top: 10px;
`;
export const WinTitle = styled.h1`
  color: rgba(110, 172, 22, 1);
  font-weight: 700;
  margin-block-end: 0.37em;
  font-size: 1.65em;
  margin-top: 10px;
`;
export const Title = styled.h1`
  background: ${(props) => props.theme.titleGrad};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  margin-block-end: 0.37em;
  font-size: 1.85em;
`;
export const HelperDiv = styled.div`
  ${(props) => props.theme.flex};
   @media screen and (max-width: 479px) {
    height: 310px;
  }
  
`;
export const Container = styled.div`
  text-align: center;
  max-width: 480px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  min-height: 75vh;
  position: relative;
  padding-bottom: 30px;
`;
export const BalanceText = styled.div`
  color: ${({ theme }) => theme.colorText};
  font-size: 1em;
  margin-top: 1.6em;
  font-weight: 600;
  letter-spacing: 0.18em;
  text-transform: uppercase;
`;
export const SpanTextSec = styled.div`
  color: ${({ theme }) => theme.colorText};
  font-size: 1em;
  margin-block-end: 1em;
  font-weight: 600;
  letter-spacing: 0.18em;
  text-transform: uppercase;
`;

export const BackBtn = styled.img`
  position: absolute;
  top: 82px;
  left: 10px;
`;
export const TitleWrap = styled.div`
  position: relative;
`;
export const LotWrap = styled.div`
  width: 35%;
  margin: 25px;
  transform: rotate(270deg);
`;
export const LotWrap2 = styled.div`
  width: 40%;
  margin-top: 60px !important;
  margin: 0 auto;
`;
export const ImageAbs = styled.img`
  position: absolute;
`;
