import React from "react";
import AboutModal from "./AboutModal";
import FlipRespModal from './FlipRespoModal';
import FAQModal from './FAQModal';
import HowToPlayModal from './HowToPlayModal';
import StuckModal from "./StuckModal";

const ModalManager = ({ closeFn, modal = "" }) => {
  return (
    <div>
      <AboutModal closeFn={closeFn} open={modal === "AboutModal"} />
      <FlipRespModal closeFn={closeFn} open={modal === "FlipRespModal"} />
      <FAQModal closeFn={closeFn} open={modal === "FAQModal"} />
      <HowToPlayModal closeFn={closeFn} open={modal === "HowToModal"} />
      <StuckModal closeFn={closeFn} open={modal === "StuckModal"} />
    </div>
  );
};

export default ModalManager;
