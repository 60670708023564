import React from "react";
import {
  FlipRespStyledModal,
  Title,
  Paragraph,
  ConfirmButton,
  UsflLink
} from "./Modal.styles";


const FlipRespModal = ({ closeFn = () => null, open = false }) => {
  return (
    <FlipRespStyledModal
      onEscapeKeydown={closeFn}
      isOpen={open}
      contentLabel="About"
    >
      <Title> FLIP RESPONSIBLY</Title>
      <Paragraph>
        PCF is a fun game on Polygon and we want all of our players to play
        responsibly. Please only play with Matic you are comfortable parting
        with that won’t impact your well-being. <br />
      </Paragraph>

      <Title>RESOURCES</Title>
      <Paragraph>
        Call: <UsflLink href="tel:1-800-522-4700">1-800-522-4700</UsflLink> <br />
        Chat: <UsflLink href="ncpgambling.org/chat">ncpgambling.org/chat</UsflLink> <br />
        Text: 1-800-522-4700 <br />
      </Paragraph>

      <Title>DO I HAVE A FLIPPING PROBLEM?</Title>
      <Paragraph style={{ marginBottom: "20px" }}>
        Flipping problem includes all behavior patterns that compromise,
        disrupt, or damage any personal, family, or vocational pursuits.
        Symptoms include increasing preoccupation with flipping, a need to flip
        more and more frequently, restlessness or irritability when attempting
        to stop, “chasing” losses, and loss of control manifested by
        continuation of the flipping behavior in spite of mounting, serious,
        and/or negative consequence. <br />
      </Paragraph>

      <ConfirmButton onClick={closeFn}>UNDERSTOOD</ConfirmButton>
    </FlipRespStyledModal>
  );
};

export default FlipRespModal;
