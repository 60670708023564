import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import wonSrc  from '../../images/wonNew.png';
import { setStatus, setClaimVal } from "../../redux/FliperReducer";
import WonLottieSrc from "../../Lotties/74694-confetti.json";
import Lottie from "lottie-react";
import { clientWithDraw } from '../../utils/interact';
import {
  SendBetButton,
  LottieBtnLoader,
  CongratsTitle,
  WinTitle,
  Container,
  HelperDiv,
  SpanTextSec,
  ImageAbs
} from "./styles";
import NativeBalance from './getBalance';
import SocialBar from "../Partials/Social";
import toast, { Toaster } from "react-hot-toast";


const Won = ({ address, status, streak, wonAmount }) => {
  const strike = streak;
  const [btnload, setBtnLoad] = useState(false);
  const dispatch = useDispatch();
  
  const HandleSubmit = async () => {
    setBtnLoad(true);
    let withdraw = await clientWithDraw(address);
    console.log("tEST1 ", withdraw)
    if(withdraw){
      dispatch(setClaimVal(0))
      dispatch(setStatus('normal'))
    }else{
      toast.error(
        "The Polygon network failed processing your request, please try again!"
      );
      dispatch(setStatus('normal'))
    }
  };
  return (
    <Container style={{paddingTop: '10px'}}>
      <Toaster position="top-center" reverseOrder={false} />
      <NativeBalance />
      <CongratsTitle>CONGRATS</CongratsTitle>
      <SpanTextSec>
        YOU {status} <br /> <WinTitle> {wonAmount} MATIC </WinTitle>
      {strike>=2? <SpanTextSec>YOU’RE ON A {strike} WIN STREAK</SpanTextSec> : ""}
      </SpanTextSec>
      <div >
        <HelperDiv >
        <Lottie animationData={WonLottieSrc} style={{position: 'relative', zIndex: '1'}} autoPlay loop />
        <ImageAbs src={wonSrc} />
        </HelperDiv>
        <SendBetButton onClick={HandleSubmit}>
        CLAIM YOUR REWARD
        {btnload ? (
          <LottieBtnLoader>
            <TailSpin
              height="30"
              width="30"
              color="grey"
              ariaLabel="loading"
            />
          </LottieBtnLoader>
        ) : ('')}
      </SendBetButton>
      </div>
      <SocialBar />
    </Container>
  );
};

export default Won;
