import styled from "styled-components";

export const Bars = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
`;
export const ThanksButton = styled.div`
  cursor: pointer;
  position: relative;
  ${(props) => props.theme.flex};
  border: ${(props) => props.theme.border};
  background: ${({ isActive, theme: { colors } }) =>
    isActive ? colors.mainPurple : colors.white};
  color: ${({ isActive, theme: { colors } }) =>
    isActive ? colors.white : colors.mainPurple};
  font-weight: 800;
  letter-spacing: 0.05em;
  height: 40px;
  font-size: 20px;
  width: 200px;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 20px;
`;
export const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 0.75)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 4,
    width: "320px",
    background: "#1b1b2f",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    borderRadius: "20px",
    outline: "none",
    padding: "20px 20px",
    border: "6px solid rgb(236 160 49 / 68%)",
  },
};
export const customStylesLight = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 0.75)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 4,
    width: "320px",
    background: "#ffffff",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    borderRadius: "20px",
    outline: "none",
    padding: "20px 20px",
    border: "6px solid rgb(236 160 49 / 68%)",
  },
};
export const AddMaticButton = styled.div`
  ${(props) => props.theme.flex};
  color: #ffffff;
  justify-content: space-between;
  width: 160px;
  height: 40px;
  background-color: #ffffff;
  color: #7b3fe4;
  border: 2px solid #7b3fe4;
  font-size: 0.85em;
  font-weight: 600;
  padding: 0px 10px;
  border-radius: 10px;
  cursor: pointer;
`;
export const LogOutButton = styled.div`
  ${(props) => props.theme.flex};
  width: 84px;
  height: 40px;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.mainPurple};
  border: 3px solid #7b3fe4;
  font-size: 0.85em;
  font-weight: 600;
  padding: 0px 10px;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  @media screen and (min-width: 479px) {
    width: 80px;
    z-index: 1000;
    position: absolute;
    left: 5px;
    top: 5px;
  }
`;

export const Title = styled.h1`
  background: ${(props) => props.theme.titleGrad};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  margin-block-end: 0.37em;
  font-size: 2.5em;
`;

export const StatText = styled.span`
  color: ${({ theme }) => theme.colorText};
  margin-bottom: 10px;
`;
export const StatWrap = styled.div`
  ${(props) => props.theme.flex};
  flex-direction: column !important;
  background-color: ${(props) => props.theme.statBox};
  width: 200px;
  height: 200px;

  // margin-bottom: 20px;
`;

export const StatContainer = styled.div`
  max-width: 480px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  position: relative;
  margin: 0 auto;
  gap: 20px;
`;

export const Container = styled.div`
  max-width: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  position: relative;
  margin: 0 auto;
`;
export const HomeContainer = styled.div`
  max-width: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  margin: 0 auto;
`;
export const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  margin-top: 30px;
  margin-bottom: 15px;
  z-index: 0;
`;

export const SpanText = styled.span`
  color: ${({ theme }) => theme.colorText};
  font-size: 0.75em;
`;
export const AboutText = styled.div`
  color: ${({ theme }) => theme.colorTextOpacity};
  font-size: 0.75em;
  cursor: pointer;
  z-index: 1;
`;

export const SpanTextSec = styled.div`
  color: ${({ theme }) => theme.colorText};
  font-size: 1em;
  margin-block-end: 1em;
  font-weight: 600;
  letter-spacing: 0.18em;
`;
export const SpanText2 = styled.div`
  text-transform: uppercase;
  width: 100%;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.04em;
  text-align: center;
  color: ${({ theme }) => theme.colorText};
`;


