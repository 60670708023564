import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  incrementByAmount,
  chooseBet,
  setStatus,
  setStreak,
  setClaimVal,
  setMute,
} from "../../redux/FliperReducer";
import {
  ConfirmButton,
  Paragraph,
  ModalParagraph,
  NavButton,
  NavWrapper,
  BetButton,
  AmountButton,
  ButtonWrapper,
  BetButtonWrapper,
  SpanTextSec,
  SendBetButton,
  Title,
} from "./styles";
import useSound from "use-sound";
import boopSfx from "../../sounds/switchOn.mp3";
import boopSfx2 from "../../sounds/click.mp3";
import { useMixpanel } from "react-mixpanel-browser";
import { newFlip, didClaim, getWinStreak } from "../../utils/interact";
import toast, { Toaster } from "react-hot-toast";
import { ThanksButton, SpanText2 } from "../../views/Home.Styles";
import { FaVolumeUp, FaVolumeMute } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Modal from "styled-react-modal/build/umd";
import ModalContent from "../Partials/Modal";
import SocialBar from "../Partials/Social";

const StyledModalDisclaimer = Modal.styled` 
  width: 320px;
  border: 1px solid #ccc;
  overflow: auto;
  WebkitOverflowScrolling: touch;
  borderRadius: 20px;
  outline: none;
  padding: 20px 20px;
  border: 6px solid rgb(236 160 49 / 68%);
  background-color: ${(props) => props.theme.ModalBody};
`;
const StyledModal = Modal.styled` 
  width: 300px;
  border: 1px solid #ccc;
  overflow: auto;
  WebkitOverflowScrolling: touch;
  borderRadius: 20px;
  outline: none;
  padding: 20px 20px;
  border: 6px solid rgb(236 160 49 / 68%);
  background-color: ${(props) => props.theme.ModalBody};
`;
const StuckStyledModal = Modal.styled` 
  width: 300px;
  border: 1px solid #ccc;
  overflow: auto;
  WebkitOverflowScrolling: touch;
  borderRadius: 20px;
  outline: none;
  padding: 20px 20px;
  border: 6px solid rgb(236 160 49 / 68%);
  background-color: ${(props) => props.theme.ModalBody};
`;
const Fliper = ({ address, isMuted }) => {
  const navigate = useNavigate();
  const mixpanel = useMixpanel();
  const [play] = useSound(boopSfx);
  const [play2] = useSound(boopSfx2);
  const count = useSelector((state) => state.fliper.value);
  const bet = useSelector((state) => state.fliper.bet);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [modalOpen3, setModalOpen3] = useState(false);
  const [isSubmiting, setisSubmiting] = useState(false);

  const guess = bet === "Head" ? true : false;
  const dispatch = useDispatch();

  const playSounds = (type) => {
    if (!isMuted) {
      switch (type) {
        case "1":
          play();
          break;
        case "2":
          play2();
          break;
        default:
          break;
      }
    }
  };

  const handleMute = () => {
    if (isMuted) {
      dispatch(setMute(false));
    } else {
      dispatch(setMute(true));
    }
  };

  const Fliping = async () => {
    let flipRes = await newFlip(guess, parseInt(count / 5), address.toString());
    return flipRes;
  };

  useEffect(() => {
    const isNew = localStorage.getItem("isNewUser");
    if (isNew === null || isNew === "true") {
      openModal2();
    }
  });
  // bet is what the user bet on, result is what really came out
  // address is the address of the fliper, betAmount is how much matic did he bet on (5,10,20...)
  const mixPanelEvent = (bet, result, address, betAmount) => {
    try {
      mixpanel.track("Flip Event", {
        bet: bet,
        result: result,
        winLoseRatio: bet === result ? "win" : "lose",
        FliperAddress: address,
        betDisterbution: betAmount,
      });
    } catch (e) {
      console.log(e);
      return;
    }
  };
  const HandleSubmit = async () => {
    try {
      setisSubmiting(true);
      const obj = await didClaim(address.toString());
      const wonValue = obj.amount / 1000000000000000000;
      if (obj.status) {
        const streak = await getWinStreak(address.toString());
        dispatch(setClaimVal(wonValue));
        dispatch(setStreak(streak));
        dispatch(setStatus("won"));
        setisSubmiting(false);
      } else {
        dispatch(setStatus("loading"));
        Fliping().then(async (result) => {
          // console.log(`result: ${result}`);
          if (result > 0) {
            const streak = await getWinStreak(address.toString());
            // console.log(`streak: ${streak}`);
            dispatch(setClaimVal(result / 1000000000000000000));
            dispatch(setStreak(streak));
            mixPanelEvent(bet, bet, address, count);
            dispatch(setStatus("won"));
          }
          if (result === 0) {
            if(bet === "Heads"){
            mixPanelEvent(bet, "Tails", address, count);
            }else{
              mixPanelEvent(bet, "Heads", address, count);
            }
            dispatch(setStatus("lose"));
          }
          if (result === -1) {
            toast.error(
              "The Polygon network failed processing your request, please try again!"
            );
            dispatch(setStatus("normal"));
          }
        });
      }
    } catch (e) {
      alert("somthing wrong");
      dispatch(setStatus("normal"));
      console.error(e);
    }
  };
  

  // function openModal() {
  //   setModalOpen(true);
  // }
  function closeModal() {
    setModalOpen(false);
  }
  function openModal2() {
    setModalOpen2(true);
  }
  function closeModal2() {
    setModalOpen2(false);
    localStorage.setItem("isNewUser", false);
  }
  function openModal3() {
    setModalOpen3(true);
  }
  function closeModal3() {
    setModalOpen3(false);
  }
  return (
    <div style={{ marginTop: "25px" }}>
      <Toaster position="top-center" reverseOrder={false} />
      <SpanTextSec>I CHOOSE:</SpanTextSec>
      <BetButtonWrapper>
        <BetButton
          isActive={bet === "Heads"}
          aria-label="Bet on Head"
          onClick={() => {
            playSounds("1");
            dispatch(chooseBet("Heads"));
          }}
        >
          HEADS
        </BetButton>
        <BetButton
          isActive={bet === "Tails"}
          aria-label="Bet on Tails"
          onClick={() => {
            playSounds("1");
            dispatch(chooseBet("Tails"));
          }}
        >
          TAILS
        </BetButton>
      </BetButtonWrapper>
      <SpanTextSec style={{ marginTop: "20px" }}>FOR:</SpanTextSec>
      <ButtonWrapper>
        <AmountButton
          isActive={count === 5}
          aria-label="Increment value"
          onClick={() => {
            playSounds("2");
            dispatch(incrementByAmount(5));
          }}
        >
          5 MATIC
        </AmountButton>

        <AmountButton
          isActive={count === 10}
          aria-label="Decrement value"
          onClick={() => {
            playSounds("2");
            dispatch(incrementByAmount(10));
          }}
        >
          10 MATIC
        </AmountButton>
        <AmountButton
          isActive={count === 20}
          aria-label="Decrement value"
          onClick={() => {
            playSounds("2");
            dispatch(incrementByAmount(20));
          }}
        >
          20 MATIC
        </AmountButton>
        <AmountButton
          isActive={count === 40}
          aria-label="Decrement value"
          onClick={() => {
            playSounds("2");
            dispatch(incrementByAmount(40));
          }}
        >
          40 MATIC
        </AmountButton>
        <AmountButton
          isActive={count === 80}
          aria-label="Decrement value"
          onClick={() => {
            playSounds("2");
            dispatch(incrementByAmount(80));
          }}
        >
          80 MATIC
        </AmountButton>
        <AmountButton
          isActive={count === 160}
          aria-label="Decrement value"
          onClick={() => {
            playSounds("2");
            dispatch(incrementByAmount(160));
          }}
        >
          160 MATIC
        </AmountButton>
      </ButtonWrapper>
      <SendBetButton isActive onClick={HandleSubmit} disabled={isSubmiting}>
        DOUBLE OR NOTHING
      </SendBetButton>
      
      <SpanTextSec onClick={openModal3}
        style={{
          marginTop: "25px",
          marginBottom: "25px",
          textDecoration: "underline",
          cursor: "pointer",
        }}
      >
        NEED HELP?
      </SpanTextSec>
      
      <div>
        <NavWrapper>
          <NavButton onClick={() => navigate("/")}>HOME</NavButton>
          <NavButton onClick={handleMute}>
            {!isMuted ? (
              <FaVolumeUp size="23px" />
            ) : (
              <FaVolumeMute size="23px" />
            )}
          </NavButton>
        </NavWrapper>
      </div>
      <StyledModal
        onEscapeKeydown={closeModal}
        isOpen={modalOpen}
        contentLabel="About"
      >
        <ModalContent />
        <ThanksButton onClick={closeModal} isActive={true}>
          THANKS
        </ThanksButton>
        <SpanText2>connect us using our social. website soon.</SpanText2>
        <SocialBar />
      </StyledModal>
      <StyledModalDisclaimer
        onEscapeKeydown={closeModal2}
        isOpen={modalOpen2}
        contentLabel="About"
      >
        <Title>DISCLAIMER</Title>
        <Paragraph>
          I confirm flipping isn't forbidden in my jurisdiction and I'm at least
          18 years old.
        </Paragraph>
        <ConfirmButton onClick={closeModal2}>CONFIRM</ConfirmButton>
      </StyledModalDisclaimer>
      <StuckStyledModal
        onEscapeKeydown={closeModal3}
        isOpen={modalOpen3}
        contentLabel="Stuck"
      >
        <Title>ARE YOU STUCK?</Title>
        <ModalParagraph>
          Polygon may still be processing your previous flip. This may result in
          a UI glitch where you see your previous coin flip's result. <br/> <br/>We will
          not double transact you without completing a flip. Pause for a bit,
          re-evaluate your strategy, and then try again once Polygon syncs up.<br/><br/>
          Join our <a href="https://discord.io/maticplay">discord</a> for more
          support.
        </ModalParagraph><br/>
        <ConfirmButton onClick={closeModal3}>CONFIRM</ConfirmButton>
      </StuckStyledModal>
    </div>
  );
};
export default Fliper;
